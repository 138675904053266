<template>
  <v-container fluid class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary">mdi-cog</v-icon>Configurações
            </h3>
          </v-card-title>
          <v-card-text>
            <v-tabs fixed-tabs>
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
              <!-- 0 - Configuração de Menu-->
              <v-tab-item>
                <Menu></Menu>
              </v-tab-item>
              <!-- 1 - Credenciais de API-->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div class="my-4 subtitle-2">
                      Credenciais da API
                    </div>
                    <div>
                      Use a nossa API para programar com os dados da sua
                      organização. O Token é necessário para o acesso da API de
                      forma segura.
                    </div>
                    <v-col cols="12">
                      <v-text-field
                        ref="textToCopy"
                        v-model="token"
                        label="Token da API"
                        dense
                        outlined
                        readonly
                        append-outer-icon="mdi-content-copy"
                        @click:append-outer="copyToText"
                      ></v-text-field>
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- 2 - Configurações de E-mail -->
              <v-tab-item>
                <EmailConfiguration></EmailConfiguration>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Menu from "./menuTab/MenuTabList";
import EmailConfiguration from "./emailTab/EmailConfiguration";

export default {
  components: {
    Menu,
    EmailConfiguration
  },
  data() {
    return {
      token: "ae90d0jsd8d7s6d6sd9sd9gn7n6v5b",
      items: [
        "Configuração de Menu",
        "Credenciais de API",
        "Configuração de Email"
      ]
    };
  },

  methods: {
    copyToText() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>
